import React from "react"
import News from "../../../blocks/en/news_page"
import { Link, graphql } from "gatsby"

export const pageQuery = graphql`
query {
  news: newsYaml(slug: { eq: "osipov_contract" }) {
    ...NewsInfoEn
  }
}`

export default class NewsTextEn extends News {
  renderNews() {
    return (
      <div className="row">
        <div className="col-xl-12">
          <p>On 5 February 2020, Catenaccio successfully represented a footballer <Link to={ `/en/players/osipov` }>Artem Osipov</Link> in a breach of contract proceeding before FIFA Dispute Resolution Chamber against a Lithuanian football club. Catenaccio persuaded the DRC to invalidate certain express terms of an employment contract by convincing the panel to apply the test of "potestativity". Granting the firm’s motion for the adjudication on the pleadings, the DRC allowed Artem to recover his contractual salary, in full, and awarded financial compensation to him.</p>
        </div>
      </div>
    )
  }
}